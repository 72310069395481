import React from "react";
import "./styles.scss";

import arrowLeft from "../../../assets/icons/arrows/arrow-right-charcoal.svg";
import FlightClassAvailable from "components/FlightClassAvailable";
import { textCapitalize } from "utils/textCapitalize";

interface ListItemAlertProps {
  iconLeft?: string;
  from?: string;
  to?: string;
  date?: string;
  type?: string;
  typeClass?: string[];
  rightNodeContent?: React.ReactNode;
  hasArrow?: boolean;
  hasSeparator?: boolean;
  className?: string;
  onClick?: (a: any) => void;
}

const ListItemAlert: React.FC<ListItemAlertProps> = (props) => {
  const {
    iconLeft,
    from,
    to,
    date,
    type,
    typeClass,
    rightNodeContent,
    hasArrow,
    hasSeparator,
    className,
    onClick,
  } = props;

  console.log(typeClass);

  return (
    <div className={`list-item-alert ${className ?? ""}`} onClick={onClick}>
      {iconLeft && <img src={iconLeft} alt="left icon" />}

      <div className="list-item-split">
        <div className="list-item-main">
          <div className="list-item-main-top">
            {from && from}
            {hasArrow && (
              <img className="list-item-icon" src={arrowLeft} alt="" />
            )}
            {to && to}
          </div>
          {date && <span className="list-item-main-bot">{date}</span>}
        </div>

        {type && <span className="list-item-pill">{type}</span>}
      </div>

      {typeClass && (
        <div className="list-item-secondary">
          <p className="no-margin">
            {typeClass.length === 1
              ? typeClass[0]
              : typeClass.length > 1
              ? "Available"
              : "All"}
          </p>
          <FlightClassAvailable
            seatsAvailableList={typeClass}
            allClassesSelected={typeClass.length === 0}
          />
        </div>
      )}

      {rightNodeContent && (
        <section className="list-item-extra">{rightNodeContent}</section>
      )}

      {hasSeparator && <div className="separator" />}
    </div>
  );
};

export default ListItemAlert;
