import { useEffect, useState } from "react";
import "./styles.scss";
import Desk from "components/Wrappers/Desk";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { AirportPin } from "types/airportsPins";
import FlightDetailsPageLink from "./FlightDetailsPageLink";
import { useModal } from "contexts/useModal";
import { useNavigate } from "react-router-dom";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import LoginAndCreateAccountModalContent from "./LoginAndCreateAccountModalContent";

const DetailsFlightByLinkPage = () => {
  const navigate = useNavigate();
  const { originDestiny } = useSearchOriginDestiny();
  const { setModalContent, setOpenUseModal } = useModal();
  const [airportsPinsState, setAirportsPinsState] = useState<AirportPin[]>([]);
  const { componentSecondary, componentTertiary } = useDeskNavigation();

  const navigateToLogin = () => {
    navigate("/app");
  };

  const navigateToHome = () => {
    navigate("/home");
  };

  const onChangeInputSearch = () => {};

  const openLoginModal = () => {
    setModalContent(
      <LoginAndCreateAccountModalContent navigateToHome={navigateToHome} />
    );
    setTimeout(() => {
      setOpenUseModal(true);
    }, 0);
  };

  return (
    <div className="details-flight-by-link">
      <Desk
        listFavoriteFlights={() => {}}
        onClickLogin={openLoginModal}
        clickOnLogo={openLoginModal}
        isALinkHeader
        clickProfileAvatar={navigateToLogin}
        profileAvatar={""}
        principalMain={<FlightDetailsPageLink />}
        secondaryMain={componentSecondary}
        tertiaryMain={componentTertiary}
        hasANewMessage={false}
        onChangeInputSearch={onChangeInputSearch}
        valueInputSearch={""}
        airportsPins={airportsPinsState}
        originCoordinates={{
          iataCode: originDestiny?.origin?.iata,
          lat: originDestiny?.origin?.lat,
          long: originDestiny?.origin?.lon,
        }}
        destinyCoordinates={{
          iataCode: originDestiny?.destiny?.iata,
          lat: originDestiny?.destiny?.lat,
          long: originDestiny?.destiny?.lon,
        }}
      />
    </div>
  );
};

export default DetailsFlightByLinkPage;
