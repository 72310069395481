import React, { useEffect, useState } from "react";
import "./styles.scss";
import travelLine from "../../../assets/icons/arrows/destination-route-line-hor-gray.svg";
import AirPlane from "../../../assets/icons/airPlanes/airplane-faded.svg";
import AirPlaneOrange from "../../../assets/icons/airPlanes/airplane-orange.svg";
import airplaneIconGray from "../../../assets/icons/airPlanes/airplane-gray.svg";
import SeatGrayIcon from "../../../assets/icons/seat/seat-arrow-gray.svg";
import { useUserInfo } from "contexts/userInfo";
import { ClassesFlightsType, FlightsClassDetails } from "types/flightDetails";
import Select, { IOptionSelectProps } from "components/Selects/Select";
import Separator from "components/Separator";
import Loading from "components/Loading";
import clockIcon from "../../../assets/icons/clocks/clock-icon-gray.svg";
import { flightClassName } from "utils/flightClassName";
import { getAirportsByIATA } from "utils/getAirportsByIATA";
import { FlightAvailability } from "types/flights";
import ArrowRight from "../../../assets/icons/arrows/arrow-right-white.svg";
import FlightClassAvailable from "components/FlightClassAvailable";

interface AirlinesTicketsCardProps {
  tripData: FlightsClassDetails[];
  searchedFlight: FlightAvailability;
  coverImage?: string;
  selectProgramCallBack: (program: {
    source: string;
    mileageProgram: string;
  }) => void;
  programs: {
    source: string;
    mileageProgram: string;
  }[];
}

type NormalizedData = {
  id: string;
  originAirportIATA: string;
  originAirportCityName: string;
  destinationAirportIATA: string;
  destinationAirportCityName: string;
  departsHourFormatted: string;
  stops: string | number;
  taxes: number | string;
  milesAmount: number | string;
  remainingSeats: number;
  arrivesAt: string;
  departsAt: string;
  flightTime: string;
  class: string;
  program: string;
};

type LineInfo = {
  id: string;
  originAirportIATA: string;
  program: string;
  originAirportCityName: string;
  destinationAirportIATA: string;
  destinationAirportCityName: string;
  miles: string;
  class: string;
};

const AirlinesTicketsCard: React.FC<AirlinesTicketsCardProps> = (props) => {
  const { tripData, programs, searchedFlight, coverImage } = props;

  const [tripNormalizedInfo, setTripNormalizedInfo] = useState<
    NormalizedData[]
  >([]);
  const [filteredTrips, setFilteredTrips] = useState<NormalizedData[]>([]);
  const [timesFlight, setTimesFlight] = useState<IOptionSelectProps[]>([]);
  const [selectStopsState, setSelectStopsState] = useState("");
  const [selectTimeState, setSelectTimeState] = useState("");
  const [loading, setLoading] = useState(true);
  const [lineInfoAvailable, setLineInfoAvailable] = useState<LineInfo>(
    {} as LineInfo
  );
  const { userInfo } = useUserInfo();
  const token = userInfo["@air-fly_access-token"];

  useEffect(() => {
    timeFlightSelect();
  }, [selectStopsState]);

  useEffect(() => {
    const stops = stopsSelect();
    if (stops.length >= 1) {
      setSelectStopsState(stops[0].value);
    }
  }, [tripNormalizedInfo]);

  useEffect(() => {
    setTripNormalizedInfo([]);
    setFilteredTrips([]);
    normalizationTripData();
    populateLineInfo();
  }, []);

  useEffect(() => {
    const timeDeparts = timesFlight[0]?.value
      ? JSON.parse(timesFlight[0]?.value).departsAt
      : null;

    const timeArrives = timesFlight[0]?.value
      ? JSON.parse(timesFlight[0]?.value).arrivesAt
      : null;

    let filteredArr = tripNormalizedInfo
      .filter((program) => program.program === programs[0]?.source)
      .filter((stops) => String(stops.stops) === selectStopsState);

    if (selectTimeState) {
      const timeDepartsSelected = JSON.parse(selectTimeState).departsAt;
      const timeArrivesSelected = JSON.parse(selectTimeState).arrivesAt;

      filteredArr = filteredArr.filter(
        (time) =>
          time.departsAt === timeDepartsSelected &&
          time.arrivesAt === timeArrivesSelected
      );
    } else if (timeDeparts && timeArrives) {
      filteredArr = filteredArr.filter(
        (time) =>
          time.departsAt === timeDeparts && time.arrivesAt === timeArrives
      );
    }

    setFilteredTrips(filteredArr);
  }, [programs, selectStopsState, timesFlight, selectTimeState]);

  const normalizationTripData = async () => {
    if (!tripData || !Array.isArray(tripData)) {
      setTripNormalizedInfo([]);
      setLoading(false);
      return;
    }

    const IATADestination =
      tripData[0].AvailabilitySegments[
        tripData[0].AvailabilitySegments.length - 1
      ].DestinationAirport;
    const IATAOrigin = tripData[0].AvailabilitySegments[0].OriginAirport;

    const cityDestination = await getAirportsByIATA(token, IATADestination);
    const cityOrigin = await getAirportsByIATA(token, IATAOrigin);

    const normalizedDataPromises = tripData
      .filter((flight) => flight.RemainingSeats > 0)
      ?.map(async (data) => {
        return {
          id: data.ID,
          originAirportIATA: IATAOrigin,
          program: data.Source,
          originAirportCityName: cityOrigin,
          destinationAirportIATA: IATADestination,
          destinationAirportCityName: cityDestination,
          stops: data.Stops,
          taxes: (data.TotalTaxes / 100).toFixed(2).replace(".", ","),
          milesAmount: formatNumberUS(data.MileageCost),
          remainingSeats: data.RemainingSeats,
          arrivesAt: formatTo12HourTime(data.ArrivesAt),
          departsAt: formatTo12HourTime(data.DepartsAt),
          departsHourFormatted: formatToShortDate(data.DepartsAt),
          flightTime: minutesInHoursFormatter(data.TotalDuration),
          class: flightClassName(data?.Cabin) || "",
        };
      });

    const result = await Promise.all(normalizedDataPromises);

    setTripNormalizedInfo(result);
    setFilteredTrips(result);
    setLoading(false);
  };

  const populateLineInfo = async () => {
    const cityDestination = await getAirportsByIATA(
      token,
      searchedFlight.route.destinationAirport
    );
    const cityOrigin = await getAirportsByIATA(
      token,
      searchedFlight.route.originAirport
    );

    const seatType = [
      searchedFlight.YAvailable ? "economic" : null,
      searchedFlight.WAvailable ? "premium" : null,
      searchedFlight.JAvailable ? "executive" : null,
      searchedFlight.FAvailable ? "first" : null,
    ].filter(Boolean) as ClassesFlightsType[];

    const minMilesValue = () => {
      const mileageCosts = searchedFlight
        ? [
            Number(searchedFlight.WMileageCost || Infinity),
            Number(searchedFlight.YMileageCost || Infinity),
            Number(searchedFlight.JMileageCost || Infinity),
            Number(searchedFlight.FMileageCost || Infinity),
          ]
        : [Infinity];

      const filteredCosts = mileageCosts.filter((cost) => cost !== 0);

      const minMileageCost =
        filteredCosts.length > 0 ? Math.min(...filteredCosts) : 0;

      return minMileageCost;
    };

    const lineObjet = {
      id: searchedFlight.id,
      originAirportIATA: searchedFlight.route.originAirport,
      program: searchedFlight.source,
      originAirportCityName: cityOrigin,
      destinationAirportIATA: searchedFlight.route.destinationAirport,
      destinationAirportCityName: cityDestination,
      miles: formatNumberUS(minMilesValue()),
      class: flightClassName(seatType[0]) || "",
    };

    setLineInfoAvailable(lineObjet);
  };

  const formatTo12HourTime = (isoString: string | Date): string => {
    const date = new Date(isoString);

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    const minutesStr = minutes.toString().padStart(2, "0");

    return `${hours}:${minutesStr} ${period}`;
  };

  const minutesInHoursFormatter = (minutes: number) => {
    const horas = Math.floor(minutes / 60);
    let rest: string | number = minutes % 60;
    if (rest < 10) {
      rest = "0" + rest;
    }

    return `${horas}:${rest}h`;
  };

  const formatToShortDate = (isoString: string | Date): string => {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const formatNumberUS = (number: number) => number.toLocaleString("en-US");

  const handleProgramSelect = (programSource: string) => {};

  const handleTimesSelect = (time: string) => {
    setSelectTimeState(time);
  };

  const handleStopsSelect = (stops: string) => {
    setSelectTimeState("");
    setSelectStopsState(stops);
  };

  const classes = [
    {
      id: 1,
      class: "economic",
      alternativeName: "economy",
      normalizedName: "Economic",
    },
    {
      id: 2,
      class: "premium",
      alternativeName: "premium",
      normalizedName: "Premium",
    },
    {
      id: 3,
      class: "business",
      alternativeName: "executive",
      normalizedName: "Executive",
    },
    {
      id: 4,
      class: "first",
      alternativeName: "first",
      normalizedName: "First Class",
    },
  ];

  const stopsSelect = (): IOptionSelectProps[] => {
    const options = Array.from(
      new Map(
        tripNormalizedInfo
          ?.map((stops) => stops?.stops)
          .map((options) => ({
            label: options ? `${options}-Stop` : "Direct",
            value: String(options),
          }))
          .map((item) => [JSON.stringify(item), item])
      ).values()
    ).sort((a, b) => Number(a.value) - Number(b.value));

    return options;
  };

  const timeFlightSelect = () => {
    setTimesFlight(
      tripNormalizedInfo
        ?.filter((flight) => String(flight.stops) === selectStopsState)
        .map((detail) => {
          return {
            label: `${detail?.departsAt} - ${detail?.arrivesAt}`,
            value: JSON.stringify({
              departsAt: detail?.departsAt,
              arrivesAt: detail?.arrivesAt,
            }),
          };
        })
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={`airline-tickets-cards ticket-details no-padding`}>
      {coverImage ? (
        <section className="airline-tickets-cards__cover_image">
          <img
            className="cover_image__image"
            src={coverImage}
            alt={`${filteredTrips[0]?.destinationAirportCityName} cover`}
          />
          <div className="cover_image__text-wrapper">
            <p className="text-wrapper__departure-destination">
              {filteredTrips[0]?.originAirportCityName}{" "}
              <img src={ArrowRight} alt="arrow right" />{" "}
              {filteredTrips[0]?.destinationAirportCityName}
            </p>
            <p className="text-wrapper__destination">
              {filteredTrips[0]?.destinationAirportCityName}
            </p>
          </div>
        </section>
      ) : (
        false
      )}

      <div className={`airline-tickets-li`}>
        {programs.length > 1 && (
          <Select
            options={programs?.map((program) => {
              return { label: program.mileageProgram, value: program.source };
            })}
            onChange={handleProgramSelect}
            labelSelect="Miles Program"
            defaultValueText={programs[0]?.mileageProgram}
            disabled={!programs.length}
            iconLeft={airplaneIconGray}
            titleText="Available Flights"
          />
        )}

        <div className="stops-and-flight-time">
          <Select
            options={stopsSelect()}
            onChange={handleStopsSelect}
            labelSelect="Flight Options"
            defaultValueText={stopsSelect()[0]?.label}
            disabled={stopsSelect().length <= 1}
          />

          <Select
            options={timesFlight}
            onChange={handleTimesSelect}
            defaultValueText={`${timesFlight[0]?.value}`}
            disabled={timesFlight.length <= 1}
            iconLeft={clockIcon}
          />
        </div>

        <Separator marginVert={16} />
        <section
          key={filteredTrips[0]?.id}
          className="airline-ticket-card__classes-details"
        >
          {filteredTrips.length ? (
            <>
              <section className="airline-tickets__from">
                <p className="no-margin header-section-text">FROM</p>
                <p className="no-margin airport-acronym">
                  {filteredTrips[0]?.originAirportIATA}
                </p>
                <p className="no-margin airport-city">
                  {filteredTrips[0]?.originAirportCityName}
                </p>

                <p className="no-margin footer-section-text">
                  {filteredTrips[0]?.departsAt}
                </p>
              </section>
              <section className="airline-tickets__details">
                <p className="no-margin travel-time">
                  {filteredTrips[0]?.flightTime}
                </p>
                <img src={travelLine} alt="line travel" />
                <p className="no-margin travel-week-date">
                  {filteredTrips[0]?.departsHourFormatted}
                </p>

                <div className="details__stops">
                  <p className="stops no-margin">
                    {filteredTrips[0]?.stops
                      ? `${filteredTrips[0]?.stops}-Stop`
                      : "Direct"}
                  </p>
                </div>
              </section>
              <section className="airline-tickets__to">
                <p className="no-margin header-section-text">TO</p>
                <p className="no-margin airport-acronym">
                  {filteredTrips[0]?.destinationAirportIATA}
                </p>
                <p className="no-margin airport-city">
                  {filteredTrips[0]?.destinationAirportCityName}
                </p>

                <p className="no-margin footer-section-text">
                  {filteredTrips[0]?.arrivesAt}
                </p>
              </section>
              <section className="seat-price-ticket-container">
                <div className="seat-price-ticket-container__details">
                  {/* {filteredTrips
                    .map((flight) => flight.milesAmount)
                    .includes(lineInfoAvailable.miles) && ( */}
                  <div className="line-info-available">
                    <hr className={`dotted-hr`} />
                    <div className="first-column">
                      <img src={AirPlaneOrange} alt="airplane icon" />
                      <div>
                        <p className="line__text">{lineInfoAvailable.class}</p>
                        <FlightClassAvailable
                          isUniqueClass
                          flight={searchedFlight}
                          className="dots-container"
                        />
                      </div>
                    </div>

                    <div className="best-flight">
                      <p>Best</p>
                    </div>

                    <p className={`no-margin ticket-price`}>
                      {lineInfoAvailable.miles}
                    </p>
                  </div>
                  {/* )} */}

                  {classes.map((classSeat, indexClass) => (
                    <React.Fragment key={indexClass}>
                      {filteredTrips.find(
                        (trip) =>
                          trip.class === classSeat.class ||
                          trip.class === classSeat.alternativeName
                      )?.remainingSeats ? (
                        <React.Fragment key={indexClass}>
                          <hr className={`dotted-hr`} />
                          <div className="details__line">
                            <div className="seat-icon-type">
                              <img src={AirPlane} alt="airplane icon" />
                              <div className={`no-margin ticket-seat-type`}>
                                <p className="line__text">
                                  {classSeat.normalizedName}
                                </p>
                                <div className="dots">
                                  {classes.map((item, index) => (
                                    <span
                                      key={item.id}
                                      className={`dot-ticket ${item.id} ${
                                        index + 1
                                      } ${
                                        indexClass + 1 === item.id
                                          ? "highlight"
                                          : ""
                                      }`}
                                    ></span>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="seats-remaining">
                              <img src={SeatGrayIcon} alt="seat airplane" />
                              <p className="seats-remaining__amount no-margin">
                                {
                                  filteredTrips.find(
                                    (trip) =>
                                      trip.class === classSeat.class ||
                                      trip.class === classSeat.alternativeName
                                  )?.remainingSeats
                                }
                              </p>
                            </div>
                            <div className="ticket-price-and-type">
                              <div className="ticket-price-and-type__prices">
                                <p className={`no-margin ticket-price`}>
                                  {
                                    filteredTrips.find(
                                      (trip) =>
                                        trip.class === classSeat.class ||
                                        trip.class === classSeat.alternativeName
                                    )?.milesAmount
                                  }
                                </p>

                                <p className="no-margin ticket-plus">
                                  + $
                                  {
                                    filteredTrips.find(
                                      (trip) =>
                                        trip.class === classSeat.class ||
                                        trip.class === classSeat.alternativeName
                                    )?.taxes
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        false
                      )}
                    </React.Fragment>
                  ))}
                  {!filteredTrips.length && (
                    <>
                      <hr className={`dotted-hr`} />
                      <p className="ticket-price">Sold out</p>
                    </>
                  )}
                </div>
              </section>
            </>
          ) : (
            <p className="unable-flights-texts">
              Oops... Unable to find flights with selected filters
            </p>
          )}
        </section>
      </div>
    </div>
  );
};

export default AirlinesTicketsCard;
