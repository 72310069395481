import { useNavigate } from "react-router-dom";
import "./styles.scss";
import ListItemAlert from "components/Lists/ListItemAlert";
import HeaderNavigate from "components/Headers/HeaderNavigate";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useUserInfo } from "contexts/userInfo";
import closeIconPeach from "../../assets/icons/close/close-icon-peach.svg";
import alertsMock from "mocks/alertsMock";
import PageTitle from "components/PageTitles";
import SlidUpFilterFlights, {
  FilterObjectSlidUp,
} from "components/Modal/SlidUpFilterFlights";
import { useEffect, useState } from "react";
import getAirportsCodes from "api/flights/getAirportsCodes";
import { IAirportsSearch } from "types/airportsSearch";
import { IOptionInputTextSelectProps } from "components/Inputs/InputTextSelect";
import BellOrange from "../../assets/icons/bells/bell-orange.svg";
import BellFaded from "../../assets/icons/bells/bell-faded.svg";
import TextActionCard from "components/Cards/TextActionCard";
import { useIconContext } from "contexts/useIconNav";
import { useModal } from "contexts/useModal";
import Button from "components/Buttons/Button";
import getUserAlerts from "api/alerts/getUserAlerts";
import ModalFilterFlights from "components/Modal/ModalFilterFlights";
import AirPlaneOrange from "../../assets/icons/airPlanes/airplane-orange.svg";
import postUserAlerts, {
  PostUserAlertsBodyType,
} from "api/alerts/postUserAlerts";
import { IAlerts } from "types/alerts";
import Loading from "components/Loading";
import { IToast } from "types/toast";
import Toast from "components/Toast";
import deleteUserAlert from "api/alerts/deleteUserAlerts";
import VibratingCellPhone from "../../assets/icons/cellphones/vibrating-cellphone.svg";
import ListItemToggle from "components/Lists/ListItemToggle";
import { useWindowWidthSize } from "contexts/useWindowWidthSize";

const MyAlertsPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { openUseModal, setModalContent, setOpenUseModal } = useModal();

  const { userInfo } = useUserInfo();
  const navigate = useNavigate();
  const [isSlideUpVisible, setIsSlidUpVisible] = useState(false);
  const [isVerifiedPhone, setIsVerifiedPhone] = useState(false);
  const [alertList, setAlertList] = useState<IAlerts[]>([]);
  const [inputSearchResultOptions, setInputSearchResultOptions] = useState(
    {} as IOptionInputTextSelectProps[]
  );
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [shouldReload, setShouldReload] = useState(0);
  const { windowSize } = useWindowWidthSize();

  useEffect(() => {
    const verifyUserInfo = JSON.parse(userInfo["@air-fly_phone-verified"]);
    const phoneNumber = userInfo["@air-fly_user_phone"];
    if (verifyUserInfo && phoneNumber !== "null") {
      setIsVerifiedPhone(true);

      userAlertList();
    } else {
      setIsVerifiedPhone(false);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [shouldReload]);

  const userAlertList = async () => {
    try {
      const request = await getUserAlerts(userInfo["@air-fly_access-token"]);

      setAlertList(request.res);
    } catch (error) {
      console.error(error);
    }
  };

  const goBack = () => {
    setPrincipalScreen("/search-milesy");

    handleButtonClick("grid");
  };

  const inviteFriendsHandler = () => {};

  const handleInputChange = async (search: string, category?: string) => {
    try {
      const request = await getAirportsCodes(
        userInfo["@air-fly_access-token"],
        {
          search,
        }
      );

      setInputSearchResultOptions(
        request.res.data.map((airport: IAirportsSearch) => {
          return {
            label: `${airport.iata} - ${airport.name}`,
            detailsText: `${airport.city}, ${airport.country}`,
            value: airport.iata,
          } as IOptionInputTextSelectProps;
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const searchFilteredConsultCallback = async (data: FilterObjectSlidUp) => {
    try {
      // y: "economic",
      // w: "premium",
      // j: "executive",
      // f: "first",
      const classSymbol: "Y" | "W" | "J" | "F" | undefined =
        data.classSelected === "economic"
          ? "Y"
          : data.classSelected === "premium"
          ? "W"
          : data.classSelected === "executive"
          ? "J"
          : data.classSelected === "first"
          ? "F"
          : undefined;

      const prepareParams: PostUserAlertsBodyType = {
        classes: classSymbol ? [classSymbol] : undefined,
        date: data.dateSelected,
        destination: data.destinationIATA,
        origin: data.departureIATA,
        direct:
          data.stopsSelected === "Direct"
            ? true
            : data.stopsSelected === "Stopover"
            ? false
            : undefined,
      };
      const request = await postUserAlerts(
        userInfo["@air-fly_access-token"],
        prepareParams
      );
      switch (request.status) {
        case 201:
          setShouldReload((prev) => prev + 1);
          setToastConfig({
            description: "Your alert has been successfully created",
            title: "Alert Added",
            type: "success",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
        case 400:
          if (userInfo["@air-fly_user-plan"] === "global") {
            setToastConfig({
              description: "The limit of possible alerts is three.",
              title: "Alert not added",
              type: "info",
            });
            setToastShow(true);
            setTimeout(() => {
              setToastShow(false);
            }, 4000);
          } else {
            setModalContent(
              <section className="payment-response">
                <span className="icon icon-airplane icon-md icon-orange "></span>
                <h5 className="no-margin margin-top-16">
                  <b>Oops, you need to upgrade your plan!</b>
                </h5>
                <p className="small-p margin-bottom-16">
                  Unlock all filters with our premium plans!
                </p>
                <div className="buttons-container">
                  <Button
                    children={"Upgrade Now"}
                    buttonStyle={"primary"}
                    onClick={() => {
                      handleTryAgain();
                    }}
                  />
                  <Button
                    children={"Continue Free"}
                    buttonStyle={"tertiary"}
                    onClick={() => {
                      setOpenUseModal(false);
                    }}
                  />
                </div>
              </section>
            );
            setOpenUseModal(true);
          }
          break;
        default:
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
      }
    } catch (error) {
      console.error(error);
      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });

      setToastShow(true);
      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    }
  };

  const handleTryAgain = () => {
    setOpenUseModal(false);
    setPrincipalScreen("/user-settings-subscriptions");
  };

  const addPhoneNumberPage = () => {
    if (
      userInfo["@air-fly_user-plan"] === "global" ||
      userInfo["@air-fly_user-plan"] === "travel"
    ) {
      setPrincipalScreen("/add-phone-number");
    } else {
      setModalContent(
        <section className="payment-response">
          <span className="icon icon-airplane icon-md icon-orange "></span>
          <h5 className="no-margin margin-top-16">
            <b>Oops, you need to upgrade your plan!</b>
          </h5>
          <p className="small-p margin-bottom-16">
            Unlock all filters with our premium plans!
          </p>
          <div className="buttons-container">
            <Button
              children={"Upgrade Now"}
              buttonStyle={"primary"}
              onClick={() => {
                handleTryAgain();
              }}
            />
            <Button
              children={"Continue Free"}
              buttonStyle={"tertiary"}
              onClick={() => {
                setOpenUseModal(false);
              }}
            />
          </div>
        </section>
      );
      setOpenUseModal(true);
    }
  };

  const clickFilterIcon = () => {
    if (userInfo["@air-fly_user-plan"] === "global") {
      if (windowSize <= 768) {
        setIsSlidUpVisible(!isSlideUpVisible);
      } else {
        setModalContent(
          <ModalFilterFlights
            inputSearchResultOptions={inputSearchResultOptions}
            titleHead="Create Alert"
            titleText="Destination"
            primaryButtonText="Create Alert"
            filterResultsCallback={searchFilteredConsultCallback}
            showDate
          />
        );
        setTimeout(() => {
          setOpenUseModal(true);
        }, 0);
      }
    } else {
      setModalContent(
        <section className="payment-response">
          <span className="icon icon-airplane icon-md icon-orange "></span>
          <h5 className="no-margin margin-top-16">
            <b>Oops, you need to upgrade your plan!</b>
          </h5>
          <p className="small-p margin-bottom-16">
            Unlock all features with our premium plans!
          </p>
          {/* <p className="small-p margin-bottom-16">
            Unlock all filters with the AirFly Ultimate Plan! Use the promo
            code: "AMIGOS" for 30 days trial.
          </p> */}
          <div className="buttons-container">
            <Button
              children={"Upgrade Now"}
              buttonStyle={"primary"}
              onClick={() => {
                handleTryAgain();
              }}
            />
            <Button
              children={"Continue Free"}
              buttonStyle={"tertiary"}
              onClick={() => {
                setOpenUseModal(false);
              }}
            />
          </div>
        </section>
      );
      setOpenUseModal(true);
    }
  };

  const formatToShortDate = (isoString: string | Date): string => {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const fullyClassName = (flightClass: "Y" | "W" | "J" | "F") => {
    switch (flightClass) {
      case "Y":
        return "economic";
      case "W":
        return "premium";
      case "J":
        return "executive";
      case "F":
        return "first";
      default:
        return "";
    }
  };

  const removeAlertRequest = async (alertId: string) => {
    try {
      const request = await deleteUserAlert(
        userInfo["@air-fly_access-token"],
        alertId
      );

      switch (request.status) {
        case 204:
          setShouldReload((prev) => prev + 1);
          setToastConfig({
            description: "Your alert has been successfully removed",
            title: "Alert removed",
            type: "success",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;

        default:
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
      }
    } catch (error) {
      console.error(error);
      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });

      setToastShow(true);
      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    } finally {
      setOpenUseModal(false);
    }
  };

  const removeAlertOpenModal = async (alert: IAlerts) => {
    setModalContent(
      <TextActionCard
        className="modal-confirm-remove-alert"
        principalText="Are you sure you want to remove this alert?"
        secondaryText={`Flight from ${alert.origin} to ${
          alert.destination
        }, in ${formatToShortDate(alert.date)}?`}
        headerIcon={AirPlaneOrange}
        buttons={[
          {
            text: "Cancel",
            buttonAction: () => setOpenUseModal(false),
            buttonStyle: "tertiary",
          },
          {
            buttonStyle: "primary",
            text: "Confirm remove",
            buttonAction: () => {
              removeAlertRequest(alert.id);
              setModalContent(
                <div className="modal-confirm-remove-alert">
                  <Loading className="modal-confirm-remove-alert__loading" />
                </div>
              );
            },
          },
        ]}
      />
    );

    setOpenUseModal(true);
  };

  const dailyUpdatesHandler = (a: any) => {
    console.log(a);
  };

  if (isLoading) {
    return (
      <div className="loading-container-alerts">
        <Loading />
      </div>
    );
  }
  return (
    <div className="my-alerts">
      <section>
        <HeaderNavigate
          userAvatarImage={userInfo["@air-fly_user_avatar"]}
          showLogo={false}
          listFavoriteFlights={() => {}}
        />
        <PageTitle
          title="My Alerts"
          backgroundColor="chalk"
          onBackClick={goBack}
          buttonWhite
          buttonToAdd={isVerifiedPhone}
          likeButtonAction={inviteFriendsHandler}
          addButtonAction={clickFilterIcon}
          className="flight-details__page-title padding-hor-16"
          subtitle="Manage Notification"
        />
      </section>

      <main className="my-alerts-wrapper padding-16">
        {!isVerifiedPhone ? (
          <TextActionCard
            headerIcon={BellOrange}
            buttons={[
              {
                buttonStyle: "primary",
                buttonAction: () => addPhoneNumberPage(),
                text: "Enable SMS Alerts",
              },
            ]}
            principalText="Enable SMS Alerts"
            secondaryText={`Enter your phone number to receive real-time flight 
						updates directly to your phone. Stay updated and never miss an 
						alert.`}
          />
        ) : (
          <>
            <div className="alerts-list-container">
              {alertList.length ? (
                <>
                  {alertList.map((alert, index, arr) => (
                    <ListItemAlert
                      key={alert.id}
                      className="alerts-list-container__list"
                      from={alert.origin}
                      to={alert.destination}
                      // date={alert.date}
                      date={formatToShortDate(alert.date)}
                      hasArrow
                      onClick={() => {}}
                      typeClass={alert.classes.map((classes) =>
                        fullyClassName(classes)
                      )}
                      iconLeft={BellFaded}
                      type={
                        alert.direct === true
                          ? "Direct"
                          : alert.direct === false
                          ? "Stopover"
                          : "Any"
                      }
                      hasSeparator={arr.length > index + 1}
                      rightNodeContent={
                        <img
                          onClick={() => removeAlertOpenModal(alert)}
                          src={closeIconPeach}
                          alt="remove alert"
                        />
                      }
                    />
                  ))}
                </>
              ) : (
                <TextActionCard
                  headerIcon={AirPlaneOrange}
                  principalText="You don't have alerts to display yet"
                  secondaryText="Create a list of flight alerts that interest you and receive the alert via SMS"
                  buttons={[
                    {
                      buttonAction: () => clickFilterIcon(),
                      buttonStyle: "primary",
                      text: "Create alert",
                    },
                  ]}
                />
              )}
            </div>
            <section className="whatsApp-alert-wrapper">
              <ListItemToggle
                toggleCallBack={dailyUpdatesHandler}
                isImageCircle
                toggleInitialState
                backgroundColor="ghost"
                imageLeft={VibratingCellPhone}
                highlightText="Daily Updates"
                // iconFill
                simpleText="Receive daily updates of best deals available"
              />
              <Button color="orange" buttonStyle="quaternary">
                Join WhatsApp
              </Button>
            </section>
          </>
        )}
      </main>

      <SlidUpFilterFlights
        closeSlidUpAreaCallback={setIsSlidUpVisible}
        isOpen={isSlideUpVisible}
        onChangeInput={handleInputChange}
        filterResultsCallback={searchFilteredConsultCallback}
        titleHead="Create Alert"
        titleText="Destination"
        primaryButtonText="Create Alert"
        showDate
      />

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default MyAlertsPage;
