import { ReactNode } from "react";
import { UserInfoProvider } from "./userInfo";
import { AirportsPinsProvider } from "./usePinsAirports";
import { LoginSignUpProvider } from "./useLoginSignUpNavigation";
import { DeskNavigationProvider } from "./useDeskNavigation";
import { SearchOriginDestinyProvider } from "./searchOriginDestiny";
import { ModalProvider } from "./useModal";
import { SearchedFlightProvider } from "./useSearchedFlight";
import { IconProvider } from "./useIconNav";
import { PwaProvider } from "hooks/pwa";
import { UseWindowWidthSizeProvider } from "./useWindowWidthSize";

const Contexts = ({ children }: { children: ReactNode }) => {
  return (
    <PwaProvider>
      <UserInfoProvider>
        <ModalProvider>
          <SearchedFlightProvider>
            <AirportsPinsProvider>
              <LoginSignUpProvider>
                <SearchOriginDestinyProvider>
                  <IconProvider>
                    <UseWindowWidthSizeProvider>
                      <DeskNavigationProvider>
                        {children}
                      </DeskNavigationProvider>
                    </UseWindowWidthSizeProvider>
                  </IconProvider>
                </SearchOriginDestinyProvider>
              </LoginSignUpProvider>
            </AirportsPinsProvider>
          </SearchedFlightProvider>
        </ModalProvider>
      </UserInfoProvider>
    </PwaProvider>
  );
};

export default Contexts;
