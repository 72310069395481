import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import OrderReview from "components/Cards/OrderReview";
import { FlightDetailsLink } from "types/flightDetails";
import Loading from "components/Loading";
import PageTitle from "components/PageTitles";
import ArrowRightWhite from "../../../assets/icons/arrows/arrow-right-gray.svg";
import Toast from "components/Toast";
import { IToast } from "types/toast";
import getFlightLink from "api/flightLink/getFlightLink";
import { useNavigate } from "react-router-dom";
import AirlinesTicketsCardLink from "components/Cards/AirlinesTicketsCardLink";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import { useModal } from "contexts/useModal";
import LoginAndCreateAccountModalContent from "../LoginAndCreateAccountModalContent";
import TextActionCard from "components/Cards/TextActionCard";
import SplashPaint from "../../../assets/icons/paint/splash-paint-orange.svg";
import { textCapitalize } from "utils/textCapitalize";

const FlightDetailsPageLink: React.FC = () => {
  const orderRef = useRef<HTMLDivElement>(null);
  const { setOriginDestiny } = useSearchOriginDestiny();
  const { setModalContent, setOpenUseModal } = useModal();

  const navigate = useNavigate();

  const [flightSelected, setSelectedFlight] = useState<FlightDetailsLink[]>(
    {} as FlightDetailsLink[]
  );
  const [externalLink, setExternalLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [programSource, setProgramSource] = useState("");
  const [airLines, setAirlines] = useState<string[]>([]);
  const [dates, setDates] = useState("");

  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const share = params.get("share");

    if (token) {
      getFlightsByLinkToken(token, share);
    } else {
      navigate("/app");
    }
  }, [navigate]);

  const expiredLinkModalContent = () => {
    setModalContent(
      <TextActionCard
        className="expired-link-content-modal"
        headerIcon={SplashPaint}
        principalText="Expired link"
        secondaryText={`The route you are trying to access may be from an old 
					date or all seats on the flight are already occupied.`}
        buttons={[
          {
            buttonAction: () => {},
            buttonStyle: "primary",
            text: "Find more flights",
          },
          { buttonAction: () => {}, buttonStyle: "secondary", text: "cancel" },
        ]}
      />
    );

    setOpenUseModal(true);
  };

  const getFlightsByLinkToken = async (
    token: string,
    share?: string | null
  ) => {
    try {
      setIsLoading(true);
      const request = await getFlightLink(token, share);
      const response: FlightDetailsLink[] = request.res;

      switch (request.status) {
        case 200:
          setSelectedFlight(response);

          setOriginDestiny({
            destiny: {
              lat: response[0].data.destination_coordinates.Lat,
              lon: response[0].data.destination_coordinates.Lon,
            },
            origin: {
              lat: response[0].data.origin_coordinates.Lat,
              lon: response[0].data.origin_coordinates.Lon,
            },
          });

          break;
        case 404:
          expiredLinkModalContent();
          break;
        default:
          console.error(`Unexpected response status: ${request.status}`);
          break;
      }
    } catch (error) {
      console.error("Error fetching flight data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const purchaseHandler = () => {
    if (externalLink) {
      window.open(externalLink, "_blank", "noopener,noreferrer");
    }
  };

  const selectedMilesProgram = (program: string) => {
    if (program) {
      setProgramSource(program);
      const source = flightSelected?.filter(
        (flight) => flight?.source === program
      );

      const link = source[0]?.data?.booking_links[0].link;
      setExternalLink(link || "");
    }
  };

  const normalizeSubtitle = () => {
    const base = flightSelected[0]?.data?.data[0]?.AvailabilitySegments;
    if (base?.length) {
      const airportOrigin = base[0]?.OriginAirport || "";
      const airportDestination =
        base[base.length - 1]?.DestinationAirport || "";
      return (
        <p className="flight-details-link__page-title--subtitle no-margin">
          {airportOrigin} <img src={ArrowRightWhite} alt="arrow right white" />
          &nbsp; {airportDestination}
        </p>
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <div className="flight-details-link__loading">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flight-details-link">
      <PageTitle
        subtitle={normalizeSubtitle()}
        title="Flight Details"
        backgroundColor="chalk"
        className="flight-details-link__page-title"
        hasButtonNavigation={false}
      />
      <main className="flight-details-link-main padding-hor-16">
        <AirlinesTicketsCardLink
          tripData={flightSelected}
          selectProgramCallBack={selectedMilesProgram}
          setAirLinesCallBack={setAirlines}
          setDatesCallBack={setDates}
        />

        <OrderReview
          ref={orderRef}
          order={{
            airline: airLines?.join(", "),
            dates: dates,
            program: textCapitalize(programSource),
          }}
          purchaseAction={purchaseHandler}
        />
      </main>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default FlightDetailsPageLink;
