let base_url_dev = "";
let base_url_stg = "https://miles-club-staging-d92ee94d3280.herokuapp.com";
let base_url_prod = "https://airfly-prd-b1b63626e056.herokuapp.com";

const currentURL = base_url_stg;

const servicesAPI = {
  auth: `${currentURL}/auth`,
  users: `${currentURL}/users`,
  flights: `${currentURL}/flights`,
  mileage: `${currentURL}/mileage-programs`,
  airports: `${currentURL}/airports`,
  waitlists: `${currentURL}/waitlists`,
  game: `${currentURL}/game`,
  plans: `${currentURL}/plans`,
  checkout: `${currentURL}/payments`,
  sms: `${currentURL}/sms`,
  alerts: `${currentURL}/alerts`,
} as const;

export type ServiceType = keyof typeof servicesAPI;

export const servicesApi = <T extends ServiceType>(
  service: T
): (typeof servicesAPI)[T] => {
  return servicesAPI[service];
};
