import React, { useEffect, useState } from "react";
import "./styles.scss";
import TextButton from "components/Buttons/TextButton";
import rightChevron from "../../../assets/icons/arrows/chevron-right-white.svg";
import rightArrow from "../../../assets/icons/arrows/arrow-right-white.svg";
import { FlightAvailability } from "types/flights";
import FlightClassAvailable from "components/FlightClassAvailable";
import airplaneWhite from "../../../assets/icons/airPlanes/airplane-white.svg";
import ChevronRight from "../../../assets/icons/arrows/chevron-right-white.svg";
import { useParams } from "react-router-dom";
interface TravelOffersCardProps {
  travelOffers: TravelOffers;
  className?: string;
  detailsHandler: (a: any) => void;
  isSelected: boolean;
}

export interface TravelOffers {
  destination: string;
  departure: string;
  points: string;
  image: string;
  id: string | number;
  flight: FlightAvailability;
}

const TravelOffersCard: React.FC<TravelOffersCardProps> = (props) => {
  const { travelOffers, className, detailsHandler, isSelected } = props;

  return (
    <div
      className={`travel-offers-card ${className ?? ""} ${
        isSelected ? "selected" : ""
      }`}
      onClick={detailsHandler}
    >
      <div className="travel-offer-li">
        <img src={travelOffers.image} alt="destination background" />
        <section className="destination-departure">
          <div className="destination-departure__header">
            <p>{travelOffers.destination}</p>
            <img src={rightArrow} alt="arrow right" />
            <p>{travelOffers.departure}</p>
          </div>

          <p>{travelOffers.destination}</p>
        </section>
        <section className="price-details-footer-card">
          <div className="dotted-hr" />
          <div className="availability-wrapper">
            <img src={airplaneWhite} alt="air plane" />
            <p className="available_text">Availability</p>
            <FlightClassAvailable
              flight={travelOffers.flight}
              className="dots-available"
              dotPalette="white"
            />
          </div>
          <p className="points_text">
            {travelOffers.points}{" "}
            <span>
              <img src={ChevronRight} alt="chevron right" />
            </span>
          </p>
          <div className="miles-wrapper"></div>
          {/* <TextButton text="Details" rightIcon={rightChevron} /> */}
        </section>
      </div>
    </div>
  );
};

export default TravelOffersCard;
